import { setUser, getToken } from '@utils'
import authHeader from '../auth-service/auth-header'
import axios from 'axios'

const user = () => JSON.parse(localStorage.getItem('userData'))

const API_URL = process.env.REACT_APP_API_AUTH
const refresh = axios.create()

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config
    const user = JSON.parse(localStorage.getItem('userData'))
    //set URL params
    const params = new URLSearchParams()
    params.append('refresh_token', user ? user.refresh_token : null)
    params.append('grant_type', 'refresh_token')
    let refreshFlag = false
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    if (error.response.status === 401 && !refreshFlag) {
      // Hace la solicitud de refresco de tokens

      if (user.refresh_token) {
        refreshFlag = true
        return refresh
          .post(API_URL + 'connect/token', params, config)
          .then((responseData) => {
            setUser(responseData.data)
            authHeader()
            originalRequest.headers.Authorization =
              'Bearer ' + responseData.data.access_token
            return axios(originalRequest)
          })
          .catch((error) => {
            localStorage.clear()
            window.location.href = '/login'
          })
      } else {
        console.log('Refresh token not available.')
        window.location.href = '/login'
        localStorage.clear()
      }
    }
    refreshFlag = false
    return Promise.reject(error)
  }
)
