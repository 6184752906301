// ** Logo
import { Spinner } from 'reactstrap'

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <div className="loading">
        <Spinner color="primary w-100 h-100" />
      </div>
    </div>
  )
}

export default SpinnerComponent
