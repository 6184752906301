// ** Initial State
const initialState = {
    etnias: [],
    generos: [],
    generosAutopercibidos: [],
    tiposDocumentos: [],
    profesiones: [],
    nivelesEducativos: []
  }
  
  const roles = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ETNIAS':
            return { ...state, etnias: action.data }
        case 'GET_GENEROS':
            return { ...state, generos: action.data }
        case 'GET_GENEROSAP':
            return { ...state, generosAutopercibidos: action.data }
        case 'GET_TIPOSDOCUMENTOS':
            return { ...state, tiposDocumentos: action.data }
        case 'GET_PROFESIONES':
            return { ...state, profesiones: action.data }
        case 'GET_NIVELESED':
            return { ...state, nivelesEducativos: action.data }
        default:
            return { ...state }
    }
  }
  export default roles
  