// ** Initial State
const initialState = {
    patient: {},
    allergies: [],
    familyHistories: [],
    personalHistories: [],
    medications: [],
    anthropometricData: [],
    activeProblems: [],
    chronic: [],
    solvedProblems: [],
    summaryList: [],
    riskFactors: null,
    prescriptions: [],
    tratamientos: [],
}

const medicalRecord = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PATIENT_MEDICAL_RECORD':
            return { ...state, patient: action.data }
        case 'GET_ALLERGIES':
            return { ...state, allergies: action.data }
        case 'GET_FAMILY_HISTORIES':
            return { ...state, familyHistories: action.data }
        case 'GET_PERSONAL_HISTORIES':
            return { ...state, personalHistories: action.data }
        case 'GET_MEDICATIONS':
            return { ...state, medications: action.data }
        case 'GET_ANTHROPOMETRIC_DATA':
            return { ...state, anthropometricData: action.data }
        case 'GET_ACTIVE_PROBLEMS':
            return { ...state, activeProblems: action.data }
        case 'GET_CHRONIC':
            return { ...state, chronic: action.data }
        case 'GET_SOLVED_PROBLEMS':
            return { ...state, solvedProblems: action.data }
        case 'GET_SUMMARY_LIST':
            return { ...state, summaryList: action.data }
        case 'GET_RISK_FACTORS':
            return { ...state, riskFactors: action.data }
        case 'GET_PRESCRIPTIONS':
            return { ...state, prescriptions: action.data }
        case 'GET_TRATAMIENTOS':
            return { ...state, tratamientos: action.data }
        case 'RESET':
            return {
                ...state,
                riskFactors: [],
                anthropometricData: []
            };
        default:
            return { ...state }
    }
}
export default medicalRecord